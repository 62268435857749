import {library, dom, config} from '@fortawesome/fontawesome-svg-core';

import {
    faAngleLeft as faAngleLeftLight,
    faAngleRight as faAngleRightLight,
    faAnglesLeft as faAnglesLeftLight,
    faAnglesRight as faAnglesRightLight
} from '@fortawesome/pro-light-svg-icons';

import {
    faStar as faStarRegular,
    faEye as faEyeRegular,
    faEyeSlash as faEyeSlashRegular
} from '@fortawesome/pro-regular-svg-icons';

import {
    faVideo as faVideoRegular
} from '@fortawesome/pro-regular-svg-icons';

import {
    fa360Degrees,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDoubleRight,
    faAnglesLeft,
    faAnglesRight,
    faArrowLeft,
    faArrowLeftLong,
    faArrowRight,
    faArrowRotateLeft,
    faArrowsRotate,
    faBackwardFast,
    faBan,
    faBars,
    faBoltLightning,
    faBook,
    faBookOpenCover,
    faBoxTaped,
    faCalendarDays,
    faCampground,
    faCaretDown,
    faCaretRight,
    faCartCircleCheck,
    faCartPlus,
    faCartShopping,
    faCampground,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCircleCheck,
    faCircleInfo,
    faCirclePlus,
    faCircleQuestion,
    faCircleXmark,
    faComment,
    faClapperboardPlay,
    faDownload,
    faCreditCard,
    faEnvelope,
    faEnvelopeOpenText,
    faEraser,
    faEuroSign,
    faEye,
    faEyeSlash,
    faSterlingSign,
    faChfSign,
    faFileLines,
    faForwardFast,
    faGift,
    faGiftCard,
    faGlobe,
    faHeart,
    faHouse,
    faIdCard,
    faLocationArrow,
    faLocationDot,
    faLock,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMinus,
    faPause,
    faPen,
    faPenToSquare,
    faPlay,
    faPlus,
    faPrint,
    faReceipt,
    faRecycle,
    faRightLeft,
    faRotate,
    faRotateLeft,
    faShieldKeyhole,
    faSquareMinus,
    faSquarePlus,
    faStar,
    faStreetView,
    faTag,
    faThumbsUp,
    faTimesCircle,
    faTruck,
    faTruckFast,
    faUpload,
    faUser,
    faWrench,
    faXmark,
    faXmarkLarge,
    faEarthAmericas
} from '@fortawesome/pro-solid-svg-icons';

// TODO: jeweils die square oder nicht square version entfernen!
import {
    faFacebook,
    faSquareFacebook,
    faInstagram,
    faSquareInstagram,
    faYoutube,
    faSquareYoutube,
    faPinterest,
    faSquarePinterest,
} from '@fortawesome/free-brands-svg-icons';

import {
    faSpinnerThird,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
    // light
    faAngleLeftLight,
    faAngleRightLight,
    faEyeRegular,
    faEyeSlashRegular,
    faAnglesLeftLight,
    faAnglesRightLight,

    // regular
    faStarRegular,
    faVideoRegular,

    // solid
    fa360Degrees,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDoubleRight,
    faAnglesLeft,
    faAnglesRight,
    faArrowLeft,
    faArrowLeftLong,
    faArrowRight,
    faArrowRotateLeft,
    faArrowsRotate,
    faBackwardFast,
    faBan,
    faBars,
    faBoltLightning,
    faBook,
    faBookOpenCover,
    faBoxTaped,
    faCalendarDays,
    faCampground,
    faCaretDown,
    faCaretRight,
    faCartCircleCheck,
    faCartPlus,
    faCartShopping,
    faCampground,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCircleCheck,
    faCircleInfo,
    faCirclePlus,
    faCircleQuestion,
    faCircleXmark,
    faComment,
    faClapperboardPlay,
    faDownload,
    faCreditCard,
    faEnvelope,
    faEnvelopeOpenText,
    faEraser,
    faEuroSign,
    faEye,
    faSterlingSign,
    faChfSign,
    faEarthAmericas,
    faFileLines,
    faForwardFast,
    faGift,
    faGiftCard,
    faGlobe,
    faHeart,
    faHouse,
    faIdCard,
    faLocationArrow,
    faLocationDot,
    faLock,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMinus,
    faPause,
    faPen,
    faPenToSquare,
    faPlay,
    faPlus,
    faPrint,
    faReceipt,
    faRecycle,
    faRightLeft,
    faRotate,
    faRotateLeft,
    faShieldKeyhole,
    faSquareMinus,
    faSquarePlus,
    faStar,
    faStreetView,
    faTag,
    faThumbsUp,
    faTimesCircle,
    faTruck,
    faTruckFast,
    faUpload,
    faUser,
    faWrench,
    faXmark,
    faXmarkLarge,

    // brands
    faFacebook,
    faSquareFacebook,
    faInstagram,
    faSquareInstagram,
    faYoutube,
    faSquareYoutube,
    faPinterest,
    faSquarePinterest,

    // duotone
    faSpinnerThird,
);

config.autoReplaceSvg = 'nest';

window.addEventListener('DOMContentLoaded', () => {
    dom.i2svg()
    dom.watch()
});
